// 设备维护列表表头
export const columns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '工单名称',
      align: 'left',
      width: 200,
      ellipsis: true,
      scopedSlots: { customRender: 'ticketTitle' }
    },
    {
      title: '工单来源',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'ticketSource' }
    },
    {
      title: '创建人',
      width: 100,
      align: 'center',
      dataIndex: 'creator'
    },
    {
      title: '创建时间',
      width: 180,
      align: 'center',
      dataIndex: 'dateCreated'
    },
    {
      title: '指派人',
      width: 100,
      align: 'center',
      dataIndex: 'assignUser.name',
      customRender: (text, record) => {
        const name = `${record.assignUser ? record.assignUser.name : '~'}` //es6写法
        return name
      }
    },
    {
      title: '处理状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'ticketProcessStatus' }
    },
    {
      title: '开始处理时间',
      width: 180,
      align: 'center',
      dataIndex: 'startProcessTime',
      scopedSlots: { customRender: 'startProcessTime' }
    },
    {
      title: '处理完成时间',
      width: 180,
      align: 'center',
      dataIndex: 'endProcessTime',
      scopedSlots: { customRender: 'endProcessTime' }
    },
    {
      title: '描述',
      align: 'left',
      ellipsis: true,
      dataIndex: 'description',
      scopedSlots: { customRender: 'description' }
    },
    {
      title: '操作',
      width: 120,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 处理状态
export const ticketProcessStatus = function () {
  return [
    { value: '', label: '全部' },
    { value: 'UN_PROCESS', label: '待处理' },
    { value: 'PROCESSING', label: '处理中' },
    { value: 'PROCESSED', label: '已处理' }
  ]
}

// 工单类型
export const ticketType = function () {
  return [
    { value: '', label: '全部' },
    { value: 'INSPECT', label: '巡检' },
    { value: 'REPORT', label: '上报' },
    { value: 'CAR', label: '车辆' },
    { value: 'OTHER', label: '其他' }
  ]
}

// 工单来源
export const ticketSource = function () {
  return [
    { value: '', label: '全部' },
    { value: 'AUTO  ', label: '自动生成' },
    { value: 'MANUAL', label: '人工创建' },
    { value: 'REPORT', label: '微信上报' }
  ]
}

