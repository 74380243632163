<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="当前处理人"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['currentUserId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择当前处理人',
                  }]
                }
              ]"
          treeDefaultExpandAll disabled
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择当前处理人"/>
      </a-form-item>
      <a-form-item label="处理结果" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['result']" placeholder="请输入处理结果"></a-input>
      </a-form-item>
      <a-form-item label="是否完结：" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group :options="finishedOptions" @change="radioChanged"
                       v-decorator="['finished',{initialValue: 'false',rules:[{type:'string',required:true,message:'是否完结为必填项'}],trigger:'change'}]"></a-radio-group>
      </a-form-item>
      <a-form-item label="下次处理人" v-if="this.formItem.finished=='false'"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['nextProcessUserId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    message: '请选择下次处理人',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择下次处理人"/>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="41"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="上传文件" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef"
          @getFileData="getFileData"
          :document-dir-id="42"
          :multiSelection="true"
          type="edit"
          :items="formItem.files"
        ></qiniu-file-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
  import { handleVO } from './common/handleVO'
  // import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'HandleForm',
    // mixins: [entityCURDCallback],
    components: { QiniuImageUpload, QiniuFileUpload },
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: handleVO(),
        orgUserTreeData: [],
        finishedOptions: [
          { value: 'true', label: '是' },
          { value: 'false', label: '否' }
        ]
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.loadCheckUserList()
    },
    methods: {
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        /*文件初始化展示处理赋值Ids*/
        values.files.forEach((item) => {
          this.formItem.fileIds.push(item.id)
        })
        this.form.setFieldsValue({
          currentUserId: this.formItem.currentUserId,
          result: this.formItem.result,
          finished: this.formItem.finished,
          nextProcessUserId: this.formItem.nextProcessUserId
        })
      },
      setVOFields (values) {
        var currentUserId = values.currentUserId.split('_')[2]
        var nextProcessUserId = values.nextProcessUserId !== undefined ? values.nextProcessUserId.split('_')[2] : ''
        this.formItem.currentUserId = currentUserId
        this.formItem.result = values.result
        this.formItem.finished = values.finished + ''
        this.formItem.nextProcessUserId = nextProcessUserId
      },
      handle (id) {
        this.formItem.id = id
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.csgz.ticketInfoApi.processTicket,
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = handleVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('handleSuccess')
                  this.form.setFieldsValue({
                    'currentUserId': this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
                  })
                },
                error: () => {
                  this.$emit('handleError')
                }
              })
            }
          })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            if (this.type === 'add') {
              /*默认设置执行人*/
              this.$nextTick(() => {
                const managerUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
                if (managerUserKey) {
                  this.form.setFieldsValue({
                    'currentUserId': managerUserKey
                  })
                }
              })
            } else {
              /*TODO 1.为啥在和处理？*/
              /*处理下拉树KEY*/
              var managerUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.managerUser.id)
              var executeUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.executeUser.id)
              if (managerUserKey) {
                this.form.setFieldsValue({
                  'currentUserId': managerUserKey
                })
              }
              if (executeUserKey) {
                this.form.setFieldsValue({
                  'nextProcessUserId': executeUserKey
                })
              }
            }
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.formItem.fileIds = []
        data.forEach((item) => {
          this.formItem.fileIds.push(item.id)
        })
      },
      radioChanged (e) {
        this.formItem.finished = e.target.value + ''
      }
    }
  }
</script>

<style scoped></style>