export const ticketInfoVO = function () {
  return {
    ticketInfo: {
      id: '', //当前工单id
      currentUserId: null,  //当前处理人userId
      nextProcessUserId: null,  //下一个处理人userId
      finished: 'false', //是否完结
      result: '', //处理结果/意见
      title: '', //工单标题
      ticketType: 'REPORT', //工单类型
      description: '', //描述
      instanceId: '',
      ticketSource: 'MANUAL'
    },
    imageIds: [],
    images: []
  }
}